import React, { Fragment } from 'react';
import { Link } from '@inertiajs/react';
import { MapPin, ChevronDown } from 'lucide-react';
import { Button } from "@/Components/ui/button";
import { Field, Menu, Transition } from '@headlessui/react';
import { useCmsString } from '@/hooks/cms';
import { strings } from '@/types/cms';
import { useHeaderBlock } from '@/hooks/cms';

interface Field {
    name: string;
    link: string;
}

interface HeroProps {
    fields: Field[];
}

const Hero: React.FC<HeroProps> = ({ fields }) => {
    const heroBlock = useHeaderBlock(strings.CMS_BLOCK_HERO);

    return (
        <div className="relative h-[40rem] block w-full">
            {/* Background Image */}
            <img
                src={heroBlock?.content?.image}
                className="absolute inset-0 w-full h-full object-cover"
                alt="Background"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            {/* Content */}
            <div className="relative z-10 h-full flex flex-col justify-center text-left px-8 md:px-16 lg:px-24">
                <div className="max-w-2xl">
                    <h1 className="text-3xl md:text-6xl font-normal mb-4 text-brand">
                        {heroBlock?.content?.title}
                    </h1>
                    <p className="text-white text-xl md:text-xl font-light mb-8">
                        {heroBlock?.content?.subtitle}
                    </p>
                    <Link href="/locations">
                        <Button size="sm" className="text-sm hover:bg-dark bg-brand">
                            {heroBlock?.content?.button}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Hero;