import React, { useState, useCallback, useEffect } from 'react';
import Map, { Marker, Popup, MarkerDragEvent, ViewState } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Link } from '@inertiajs/react';
import { useCmsString } from '@/hooks/cms';
import { strings } from '@/types/cms';

interface FieldProps {
    id: number;
    image: string;
    name: string;
    what3words: string;
    price: number;
    link: string;
    latitude: number;
    longitude: number;
}

interface FieldsMapProps {
    fields: FieldProps[];
    center: {
        latitude: number;
        longitude: number;
        zoom: number;
    };
}

interface ViewportState extends ViewState {
    transitionDuration: number;
    transitionEasing?: (t: number) => number;
}

const FieldsMap: React.FC<FieldsMapProps> = ({ fields, center }) => {
    const [selectedField, setSelectedField] = useState<FieldProps | null>(null);
    const [viewport, setViewport] = useState<ViewportState>({
        latitude: center.latitude,
        longitude: center.longitude,
        zoom: center.zoom + 2, // Start 2 levels higher
        transitionDuration: 0,
        bearing: 0,
        padding: { top: 0, bottom: 0, left: 0, right: 0 },
        pitch: 0
    });

    useEffect(() => {
        // After component mounts, animate to the target zoom level
        setTimeout(() => {
            setViewport({
                ...viewport,
                latitude: center.latitude,
                longitude: center.longitude,
                zoom: center.zoom,
                transitionDuration: 2000,
                transitionEasing: (t: number) => t * (2 - t) // Ease out quadratic
            });
        }, 100);
    }, []);

    const handleMarkerClick = (field: FieldProps) => {
        setSelectedField(field);
        setViewport({
            ...viewport,
            latitude: field.latitude,
            longitude: field.longitude,
            transitionDuration: 1000,
            transitionEasing: (t: number) => t * (2 - t) // Ease out quadratic
        });
    };

    return (
        <div className="w-full h-[600px] relative">
            <Map
                {...viewport}
                onMove={evt => setViewport({ ...viewport, ...evt.viewState })}
                mapboxAccessToken="pk.eyJ1IjoiYWxhbmNvbGUiLCJhIjoiY2x6c3F5YWhyMHIzaTJpczl0bGVyeDRldyJ9.U8b_EJWbEmx3Gc18a-nMWA"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                style={{ width: '100%', height: '100%' }}
                maxZoom={center.zoom}
                minZoom={center.zoom}
                scrollZoom={false}
                dragRotate={false}
                touchPitch={false}
                doubleClickZoom={false}
                keyboard={false}
            >
                {fields.map((field, index) => (
                    <Marker
                        key={field.id}
                        longitude={field.longitude}
                        latitude={field.latitude}
                        anchor="center"
                        onClick={() => handleMarkerClick(field)}
                    >
                        <div className="relative">
                            {/* Pulsating background */}
                            <div className="absolute -inset-4 bg-pink/20 rounded-full animate-pulse" />
                            {/* Solid marker */}
                            <div className="relative w-4 h-4 bg-pink rounded-full border-2 border-white shadow-lg" />
                        </div>
                    </Marker>
                ))}

                {selectedField && (
                    <Popup
                        longitude={selectedField.longitude}
                        latitude={selectedField.latitude}
                        anchor="bottom"
                        onClose={() => setSelectedField(null)}
                        className="rounded-sm"
                        closeButton={true}
                        closeOnClick={false}
                    >
                        <div className="p-2 min-w-[100px] rounded-sm">
                            <Link 
                                href={selectedField.link}
                                className="block w-full bg-brand font-bold text-white text-center py-2 px-4 rounded hover:bg-pink-600 transition-colors"
                            >
                                {selectedField.name} {useCmsString(strings.CMS_FIELDS_MAP_BOOK_NOW, 'Book Now')}
                            </Link>
                        </div>
                    </Popup>
                )}
            </Map>
        </div>
    );
};

export default FieldsMap; 