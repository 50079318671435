import React from 'react';
import * as LucideIcons from 'lucide-react';
import { LucideIcon } from 'lucide-react';
import Feature from './Feature';
import { useCmsString } from '@/hooks/cms';
import { useCollectionBlock } from '@/hooks/cms';
import { strings } from '@/types/cms';
import allIcons from '@/lib/icons';

// Function to get Lucide icon component from string name
const getIconComponent = (iconName: string | undefined): React.ComponentType<any> | undefined => {
    if (!iconName) return undefined;
    
    // Try to find the icon in the Lucide icons
    const IconComponent = (LucideIcons as any)[iconName];
    
    // If icon not found, return Tag as fallback
    return IconComponent || LucideIcons.Tag;
};

const WhyUs: React.FC = () => {
    const featuresIn = useCollectionBlock(strings.CMS_BLOCK_WHY_US);

    // Create feature items with icon components
    const features = Array.from({ length: 6 }, (_, index) => {
        const item = featuresIn?.content?.items[index];
        if (!item) return null;
        
        const IconComponent = getIconComponent(item.content.icon);
        
        return {
            Icon: IconComponent,
            title: item.content.title,
            description: item.content.text
        };
    }).filter(Boolean); // Remove any null items

    return (
        <section className="relative py-16 px-16 overflow-hidden">
            <div 
                className="absolute inset-0 bg-repeat opacity-[15%]"
                style={{ backgroundImage: 'url("/images/bone.png")', backgroundSize: '15%' }}
            />
            <div 
                className="absolute inset-0 bg-mint bg-opacity-50"
            />
            <div className="container mx-auto px-4 relative">
                <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
                    {useCmsString(strings.CMS_WHY_US_TITLE, 'Why Choose Our Doggy Exercise Fields?')}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <Feature
                            key={index}
                            Icon={feature?.Icon as LucideIcon}
                            title={feature?.title}
                            description={feature?.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyUs;