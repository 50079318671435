import { Link } from '@inertiajs/react';
import Header from '@/Components/Built/Website/Header';
import Hero from '@/Components/Built/Website/Hero';
import Callout from '@/Components/Built/Website/Callout';
import WhyUs from '@/Components/Built/Website/WhyUs';
import Testimonials from '@/Components/Built/Website/Testimonials';
import FieldsGrid from '@/Components/Built/Website/FieldsGrid';
import Footer from '@/Components/Built/Website/Footer';
import { PageProps } from '@/types';
import { User } from '@/types';
import Banner from '@/Components/Built/Website/Banner';
import FieldsMap from '@/Components/Built/Website/FieldsMap';
import InstagramFeed from '@/Components/Built/Website/InstagramFeed';
import GoogleReviews from '@/Components/Built/Website/GoogleReviews';
import SEO from '@/Components/Built/Website/SEO';

interface FieldProps {
    id: number;
    image: string;
    name: string;
    what3words: string;
    price: number;
    link: string;
    from: string;
    latitude: number;
    longitude: number;
}

interface ReviewProps {
    name: string;
    rating: number;
    text: string;
    timestamp: number;
    field_name?: string;
    profile_picture: string;
}

interface FieldsPageProps {
    auth: {
        user?: User;
    };
    fields: FieldProps[];
    center: {
        latitude: number;
        longitude: number;
        zoom: number;
    }
    reviews: ReviewProps[];
}

const Home: React.FC<FieldsPageProps> = ({ fields, auth, center, reviews }) => {
    const user = auth.user;
    
    return (
        <>
            <SEO 
                title="Welcome"
                description="Book a secure, private dog walking field at Grow Walkies. Safe, enclosed spaces for off-lead play, exercise, and socialisation. Easy online booking!"
                canonicalUrl="/"
                schema={{
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "Grow Walkies",
                    "url": "https://grow-walkies.com",
                    "logo": "https://grow-walkies.com/images/logo@2.png",
                    "description": "Secure, private dog walking fields for hire",
                    "sameAs": [
                        "https://www.facebook.com/growwalkies",
                        "https://www.instagram.com/growwalkies"
                    ]
                }}
            />
            <Banner />
            <Header user={user} />
            <Hero fields={fields} />
            <FieldsMap fields={fields} center={center} />
            <WhyUs />
            <GoogleReviews reviews={reviews} />
            <InstagramFeed />
            <Footer />
        </>
    );
}

export default Home;