import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

interface InstagramPost {
    id: string;
    media_url: string;
    permalink: string;
    caption: string;
    thumbnail_url: string;
}

const InstagramFeed: React.FC = () => {
    const [posts, setPosts] = useState<InstagramPost[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('/api/instagram/feed');
                setPosts(response.data.posts);
            } catch (error) {
                console.error('Error fetching Instagram posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[200px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-brand"></div>
            </div>
        );
    }

    if (posts.length === 0) {
        return null;
    }

    return (
        <div className="bg-gray-50 py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">Follow Our Journey</h2>
                <div className="mb-4 text-center">
                    <a 
                        href="https://www.instagram.com/grow.walkies/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-brand hover:text-brand-dark"
                    >
                        @grow.walkies
                    </a>
                </div>
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                    }}
                    className="instagram-feed-swiper"
                >
                    {posts.map((post) => (
                        <SwiperSlide key={post.id}>
                            <a
                                href={post.permalink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block aspect-square overflow-hidden rounded-lg transition-transform hover:scale-105"
                            >
                                <img
                                    src={post.thumbnail_url || post.media_url}
                                    alt={post.caption}
                                    className="w-full h-full object-cover"
                                />
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default InstagramFeed; 